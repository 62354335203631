import React from "react"

export const SparkleIcon = ({ height = 16, width = 16, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3269 23.0787C15.2169 23.1287 15.1287 23.2169 15.0787 23.3269L12.9552 27.9986C12.7775 28.3894 12.2225 28.3894 12.0448 27.9986L9.92134 23.3269C9.87131 23.2169 9.78311 23.1287 9.67306 23.0787L5.0014 20.9552C4.61061 20.7775 4.61061 20.2225 5.0014 20.0448L9.67306 17.9213C9.78311 17.8713 9.87131 17.7831 9.92134 17.6731L12.0448 13.0014C12.2225 12.6106 12.7775 12.6106 12.9552 13.0014L15.0787 17.6731C15.1287 17.7831 15.2169 17.8713 15.3269 17.9213L19.9986 20.0448C20.3894 20.2225 20.3894 20.7775 19.9986 20.9552L15.3269 23.0787Z"
        fill="white"
        stroke="#7026B9"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.91 15.91L24.5 19L23.09 15.91L20 14.5L23.09 13.09L24.5 10L25.91 13.09L29 14.5L25.91 15.91Z"
        fill="#7026B9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5967 7.59667L16.5 10L15.4033 7.59667L13 6.5L15.4033 5.40333L16.5 3L17.5967 5.40333L20 6.5L17.5967 7.59667Z"
        fill="#F06200"
      />
    </svg>
  )
}
