import React from "react"

export const ShopifyIcon = ({ height = 16, width = 16, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2493 2.99355C13.3078 2.99355 13.378 3.03943 13.3897 3.11971C13.3935 3.14563 13.5802 4.38476 13.8314 6.05148L13.8328 6.06059L13.8328 6.06074L13.8337 6.0668C14.3607 9.56343 15.1688 14.9256 15.1688 14.9333L10.1242 16L0.819336 14.291C0.819336 14.291 2.01317 5.23011 2.05999 4.90896C2.11851 4.48459 2.13021 4.47312 2.58668 4.33548C2.61114 4.3275 2.69805 4.30142 2.83555 4.26018L2.8356 4.26016L2.83571 4.26013C3.09307 4.18293 3.52755 4.05259 4.06142 3.88817C4.37743 2.58065 5.384 0 7.49076 0C7.75996 0 8.08768 0.149104 8.33347 0.470251H8.4154C9.31663 0.470251 9.83162 1.22724 10.1242 2.06452C10.2618 2.02304 10.373 1.9886 10.4547 1.9633C10.5538 1.93263 10.6094 1.91541 10.6158 1.91541C10.686 1.89247 10.8733 1.86953 10.9669 1.96129C11.0723 2.05305 11.9384 2.90179 11.9384 2.90179C11.9384 2.90179 13.1907 2.99355 13.2493 2.99355ZM8.82508 2.44303C9.10598 2.35127 9.37518 2.27098 9.60927 2.20217C9.41029 1.60575 9.10598 1.08962 8.63781 1.02081C8.75486 1.35342 8.82508 1.76633 8.82508 2.27098V2.44303ZM8.05253 1.13544C8.18127 1.45659 8.2632 1.9039 8.2632 2.52326V2.61501C7.96979 2.7065 7.66497 2.79799 7.35309 2.8916L7.35301 2.89162C7.11752 2.9623 6.878 3.03419 6.63631 3.1082C6.95233 1.92684 7.54924 1.35337 8.05253 1.13544ZM7.70144 0.642283C7.61951 0.584936 7.52588 0.550527 7.43224 0.550527C5.92239 0.550527 5.06798 2.50035 4.71685 3.69318C4.90886 3.63404 5.10602 3.57491 5.30711 3.51459C5.53504 3.44622 5.76803 3.37634 6.00432 3.30322C6.30863 1.73189 7.02259 0.951961 7.70144 0.642283Z"
        fill="currentColor"
      />
      <path
        d="M13.2493 2.98206C13.1908 2.98206 11.9384 2.89031 11.9384 2.89031C11.9384 2.89031 11.0723 2.04156 10.967 1.9498C10.9319 1.9154 10.8851 1.89246 10.8382 1.89246L10.136 16L15.1805 14.9333C15.1805 14.9333 13.4132 3.19998 13.4015 3.1197C13.3781 3.03941 13.3078 2.99353 13.2493 2.98206Z"
        fill="currentColor"
      />
      <path
        d="M8.41544 5.71184L7.79511 7.52403C7.79511 7.52403 7.24501 7.23729 6.57787 7.23729C5.59471 7.23729 5.5479 7.84518 5.5479 7.99428C5.5479 8.82009 7.7483 9.14123 7.7483 11.0796C7.7483 12.605 6.76514 13.5914 5.43085 13.5914C3.82737 13.5914 3.01978 12.6165 3.01978 12.6165L3.45283 11.2287C3.45283 11.2287 4.29554 11.9398 4.9978 11.9398C5.46597 11.9398 5.65323 11.5842 5.65323 11.3204C5.65323 10.2423 3.85078 10.1964 3.85078 8.41865C3.85078 6.92761 4.93927 5.48245 7.15138 5.48245C7.99409 5.47098 8.41544 5.71184 8.41544 5.71184Z"
        fill="white"
      />
    </svg>
  )
}
