import React from "react"

export function TwitterIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      {...props}
    >
      <path d="M14.9735 4.00002C14.4602 4.23335 13.9069 4.38669 13.3335 4.46002C13.9202 4.10669 14.3735 3.54669 14.5869 2.87335C14.0335 3.20669 13.4202 3.44002 12.7735 3.57335C12.2469 3.00002 11.5069 2.66669 10.6669 2.66669C9.10019 2.66669 7.82019 3.94669 7.82019 5.52669C7.82019 5.75335 7.84686 5.97335 7.89352 6.18002C5.52019 6.06002 3.40686 4.92002 2.00019 3.19335C1.75352 3.61335 1.61352 4.10669 1.61352 4.62669C1.61352 5.62002 2.11352 6.50002 2.88686 7.00002C2.41352 7.00002 1.97352 6.86669 1.58686 6.66669C1.58686 6.66669 1.58686 6.66669 1.58686 6.68669C1.58686 8.07335 2.57352 9.23335 3.88019 9.49335C3.64019 9.56002 3.38686 9.59335 3.12686 9.59335C2.94686 9.59335 2.76686 9.57335 2.59352 9.54002C2.95352 10.6667 4.00019 11.5067 5.26019 11.5267C4.28686 12.3 3.05352 12.7534 1.70686 12.7534C1.48019 12.7534 1.25352 12.74 1.02686 12.7134C2.29352 13.5267 3.80019 14 5.41352 14C10.6669 14 13.5535 9.64002 13.5535 5.86002C13.5535 5.73335 13.5535 5.61335 13.5469 5.48669C14.1069 5.08669 14.5869 4.58002 14.9735 4.00002Z" />
    </svg>
  )
}
