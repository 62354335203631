import React from "react"
import { generateId } from "../../shared/helpers"

export const CareersIcon = ({ width = 32, height = 32, ...rest }) => {
  const maskId = generateId()

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.4858 6.79091V5.58038"
        stroke="#7026B9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3721 6.79091V5.58038"
        stroke="#7026B9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.95898 19.4996V8.20134C7.95898 7.82678 8.10778 7.46756 8.37263 7.20271C8.63749 6.93786 8.9967 6.78906 9.37127 6.78906H23.4941C23.8686 6.78906 24.2279 6.93786 24.4927 7.20271C24.7576 7.46756 24.9064 7.82678 24.9064 8.20134V19.4996"
        fill="#FFF4DB"
        stroke="#7026B9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.0796 19.4996H6.77843C6.25845 19.4996 5.83691 19.9211 5.83691 20.4411V25.6195C5.83691 26.1394 6.25845 26.561 6.77843 26.561H26.0796C26.5996 26.561 27.0211 26.1394 27.0211 25.6195V20.4411C27.0211 19.9211 26.5996 19.4996 26.0796 19.4996Z"
        fill="white"
        stroke="#7026B9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1958 22.3242C12.1958 22.6987 12.3446 23.0579 12.6094 23.3228C12.8743 23.5876 13.2335 23.7364 13.6081 23.7364H19.2572C19.6318 23.7364 19.991 23.5876 20.2558 23.3228C20.5207 23.0579 20.6695 22.6987 20.6695 22.3242"
        stroke="#7026B9"
        strokeWidth="1.21053"
      />
      <mask
        id={maskId}
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="8"
        y="6"
        width="17"
        height="13"
      >
        <rect
          width="15.7368"
          height="12.1053"
          transform="matrix(1 0 0 -1 8.63135 18.9501)"
          fill="#C4C4C4"
        />
      </mask>
      <g mask={`url(#${maskId})`}>
        <path
          d="M21.6105 15.6547H18.3152V16.5963H20.5748C20.2453 18.0085 19.2096 19.1854 17.8444 19.6562L12.4307 14.2425C12.9956 12.5948 14.5962 11.4179 16.4322 11.4179C17.8444 11.4179 19.1155 12.124 19.9158 13.2068L20.6219 12.5948C19.6804 11.3237 18.174 10.4764 16.4322 10.4764C13.9842 10.4764 11.9129 12.2182 11.395 14.5249L17.6091 20.739C19.8687 20.174 21.6105 18.1027 21.6105 15.6547Z"
          fill="white"
        />
        <path
          d="M11.2539 15.7018C11.2539 17.0199 11.7717 18.291 12.7603 19.2796C13.7489 20.2682 15.0671 20.786 16.3381 20.786L11.2539 15.7018Z"
          fill="white"
        />
        <path
          d="M16.4324 9.06409C12.8076 9.06409 9.8418 12.0299 9.8418 15.6547C9.8418 19.2796 12.8076 22.2454 16.4324 22.2454C20.0573 22.2454 23.0231 19.2796 23.0231 15.6547C23.0231 12.0299 20.0573 9.06409 16.4324 9.06409ZM12.7605 19.3267C11.7719 18.3381 11.2541 17.0199 11.2541 15.7489L16.3854 20.8331C15.0672 20.786 13.7491 20.3153 12.7605 19.3267ZM17.5623 20.6919L11.3953 14.5249C11.9131 12.2182 13.9845 10.4764 16.4324 10.4764C18.1743 10.4764 19.6807 11.3237 20.6222 12.5948L19.9161 13.2068C19.1158 12.124 17.8447 11.4179 16.4324 11.4179C14.5965 11.4179 13.043 12.5948 12.431 14.2424L17.8447 19.6562C19.2099 19.1854 20.2456 18.0085 20.5751 16.5963H18.3155V15.6547H21.6108C21.6108 18.1027 19.869 20.174 17.5623 20.6919Z"
          fill="#7026B9"
        />
      </g>
      <path
        d="M27.1712 16.6864C28.47 16.6864 29.0544 17.2736 29.066 18.5892C29.0667 18.6671 29.0977 18.7418 29.1525 18.7973C29.2074 18.8527 29.2816 18.8847 29.3596 18.8864H29.366C29.443 18.8865 29.5172 18.8571 29.573 18.804C29.6289 18.751 29.6622 18.6785 29.666 18.6016C29.7312 17.2952 30.33 16.6864 31.5512 16.6864H31.566C31.6455 16.6872 31.7222 16.6564 31.779 16.6007C31.8359 16.5451 31.8683 16.4691 31.8692 16.3896C31.87 16.31 31.8392 16.2333 31.7836 16.1765C31.7279 16.1196 31.6519 16.0872 31.5724 16.0864C30.2688 16.0588 29.6628 15.4568 29.666 14.192V14.1872C29.6661 14.1478 29.6585 14.1088 29.6436 14.0723C29.6287 14.0359 29.6067 14.0027 29.5789 13.9748C29.5512 13.9469 29.5182 13.9247 29.4819 13.9095C29.4455 13.8943 29.4066 13.8865 29.3672 13.8864C29.3278 13.8862 29.2887 13.8938 29.2523 13.9087C29.2158 13.9237 29.1827 13.9456 29.1547 13.9734C29.1267 14.0011 29.1045 14.0341 29.0893 14.0704C29.074 14.1068 29.0661 14.1458 29.066 14.1852C29.0616 15.4644 28.4568 16.0684 27.1616 16.0864C27.082 16.0869 27.0059 16.1191 26.9501 16.1758C26.8942 16.2325 26.8632 16.309 26.8638 16.3886C26.8644 16.4681 26.8965 16.5442 26.9532 16.6C27.0099 16.6559 27.0864 16.6869 27.166 16.6864H27.1712Z"
        fill="#F06200"
      />
      <path
        d="M2.36889 4.36C3.92745 4.36 4.62873 5.06464 4.64265 6.64336C4.6435 6.73694 4.68076 6.82651 4.74653 6.89308C4.8123 6.95966 4.90141 6.99801 4.99497 7H5.00265C5.09508 7.00024 5.18406 6.96486 5.25109 6.90122C5.31813 6.83758 5.35808 6.75056 5.36265 6.65824C5.44089 5.09056 6.15945 4.36 7.62489 4.36H7.64265C7.73812 4.36102 7.8301 4.32407 7.89833 4.25727C7.96656 4.19048 8.00547 4.09932 8.00649 4.00384C8.0075 3.90836 7.97055 3.81639 7.90376 3.74816C7.83697 3.67992 7.7458 3.64102 7.65033 3.64C6.08601 3.60688 5.35881 2.88448 5.36265 1.36672V1.36096C5.36284 1.31371 5.35371 1.26688 5.33579 1.22316C5.31786 1.17944 5.2915 1.13968 5.2582 1.10615C5.22489 1.07263 5.18531 1.046 5.14171 1.02778C5.09811 1.00957 5.05134 1.00013 5.00409 1V1C4.95681 0.999813 4.90996 1.00894 4.86621 1.02685C4.82246 1.04477 4.78267 1.07113 4.74911 1.10443C4.71554 1.13772 4.68887 1.1773 4.6706 1.22091C4.65234 1.26451 4.64284 1.31129 4.64265 1.35856V1.35856C4.63737 2.8936 3.91161 3.6184 2.35737 3.64C2.26189 3.6407 2.1706 3.6793 2.10358 3.74731C2.03657 3.81532 1.99931 3.90716 2.00001 4.00264C2.00071 4.09812 2.03931 4.18941 2.10732 4.25643C2.17533 4.32344 2.26717 4.3607 2.36265 4.36H2.36889Z"
        fill="#F06200"
        stroke="#F06200"
        strokeWidth="0.306818"
      />
    </svg>
  )
}
