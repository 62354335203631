/*
  Represents active test groups in LD

  Tests are defined as objects with 'from' and 'to' properties 
  that define a regex path to match. Example:

  startersTestGroup: {
    // Matches /starters path, with optional trailing slash, and adds `/` or `` to capture group $1
    from: /^\/starters(\/|$)/,
    // $1 adds the trailing slash, if present in the path
    to: "/starters-next$1",
  },

*/

export const activeTests = {}
