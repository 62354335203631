import React from "react"

export const GatsbyCampIcon = ({ width = 32, height = 32, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <circle cx="16" cy="15.3369" r="10" fill="#639" />
    <path d="M16 13.7647L22 26H10L16 13.7647Z" fill="white" />
    <path
      d="M14.5 10L22.5 25.0588"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M17.5 10L9.5 25.0588"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M18.8153 22.1763C18.8203 22.1746 18.8222 22.1686 18.8191 22.1644C18.7074 22.0127 18.5689 21.8747 18.4236 21.7571C18.1064 21.454 17.7187 21.2354 17.4085 20.9112C16.9191 20.4264 16.6867 19.7328 16.7134 19.0465C16.7301 18.6199 16.349 18.2243 15.9971 18.466C15.9023 18.5312 15.811 18.5996 15.7238 18.6696C15.4063 18.9316 14.258 19.5648 13.7142 20.4483C13.6824 20.5 13.6432 20.5468 13.5984 20.5877C12.807 21.3093 12.4342 22.7677 12.5095 23.8435C12.5236 24.0973 12.5589 24.344 12.6435 24.5978C12.721 24.9079 12.8479 25.211 13.01 25.4789C13.5387 26.36 14.4762 26.9944 15.4771 27.1213C16.5485 27.2623 17.6905 27.0578 18.5152 26.3107C19.4316 25.4648 19.7628 24.1114 19.2765 22.9342L19.206 22.8144C19.1088 22.5854 18.9775 22.3768 18.812 22.1888C18.8083 22.1846 18.8101 22.178 18.8153 22.1763Z"
      fill="#F06200"
    />
    <line
      x1="14.75"
      y1="29.25"
      x2="17.25"
      y2="29.25"
      stroke="#F06200"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
