import React from "react"

export const AboutGatsbyIcon = ({ width = 32, height = 32, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <circle
      cx="16"
      cy="16"
      r="12"
      transform="rotate(-180 16 16)"
      fill="#F1DEFA"
    />
    <circle cx="16" cy="16" r="11" fill="white" />
    <circle cx="7" cy="9" r="2" fill="#F06200" />
    <circle cx="20" cy="5" r="1" fill="#F06200" />
    <circle cx="27" cy="13" r="2" fill="#7026B9" />
    <circle cx="8.5" cy="24.5" r="1.5" fill="#7026B9" />
    <circle cx="23" cy="25" r="1" fill="#F06200" />
    <path
      d="M16 8C14.4178 8 12.871 8.46919 11.5554 9.34824C10.2398 10.2273 9.21447 11.4767 8.60897 12.9385C8.00347 14.4003 7.84504 16.0089 8.15372 17.5607C8.4624 19.1126 9.22433 20.538 10.3431 21.6569C11.462 22.7757 12.8874 23.5376 14.4393 23.8463C15.9911 24.155 17.5997 23.9965 19.0615 23.391C20.5233 22.7855 21.7727 21.7602 22.6518 20.4446C23.5308 19.129 24 17.5823 24 16C23.9977 13.879 23.1541 11.8455 21.6543 10.3457C20.1545 8.84589 18.121 8.00229 16 8V8ZM17.6667 13C17.6667 12.8232 17.7369 12.6536 17.8619 12.5286C17.987 12.4036 18.1565 12.3333 18.3333 12.3333C18.5101 12.3333 18.6797 12.4036 18.8047 12.5286C18.9298 12.6536 19 12.8232 19 13V15C19 15.1768 18.9298 15.3464 18.8047 15.4714C18.6797 15.5964 18.5101 15.6667 18.3333 15.6667C18.1565 15.6667 17.987 15.5964 17.8619 15.4714C17.7369 15.3464 17.6667 15.1768 17.6667 15V13ZM13 13C13 12.8232 13.0702 12.6536 13.1953 12.5286C13.3203 12.4036 13.4899 12.3333 13.6667 12.3333C13.8435 12.3333 14.0131 12.4036 14.1381 12.5286C14.2631 12.6536 14.3333 12.8232 14.3333 13V15C14.3333 15.1768 14.2631 15.3464 14.1381 15.4714C14.0131 15.5964 13.8435 15.6667 13.6667 15.6667C13.4899 15.6667 13.3203 15.5964 13.1953 15.4714C13.0702 15.3464 13 15.1768 13 15V13ZM19.632 18.3647C19.2386 18.97 18.7001 19.4674 18.0656 19.8118C17.4311 20.1561 16.7206 20.3365 15.9987 20.3365C15.2767 20.3365 14.5662 20.1561 13.9317 19.8118C13.2972 19.4674 12.7588 18.97 12.3653 18.3647C12.2686 18.2165 12.2347 18.036 12.2711 17.8628C12.3075 17.6897 12.4112 17.538 12.5593 17.4413C12.7075 17.3446 12.888 17.3107 13.0612 17.3471C13.2343 17.3835 13.386 17.4872 13.4827 17.6353C13.7551 18.0542 14.1278 18.3985 14.567 18.6368C15.0062 18.875 15.498 18.9999 15.9977 18.9999C16.4974 18.9999 16.9891 18.875 17.4283 18.6368C17.8675 18.3985 18.2403 18.0542 18.5127 17.6353C18.6094 17.4873 18.761 17.3837 18.9341 17.3473C19.1072 17.311 19.2876 17.345 19.4357 17.4417C19.5837 17.5384 19.6873 17.69 19.7237 17.8631C19.76 18.0362 19.7261 18.2166 19.6293 18.3647H19.632Z"
      fill="#7026B9"
    />
  </svg>
)
