import React from "react"

export const WordpressIcon = ({ height = 16, width = 16, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1.99609 12.712C1.99609 16.7867 4.36403 20.3081 7.79772 21.9768L2.88733 8.52246C2.31615 9.80274 1.99609 11.2196 1.99609 12.712Z"
        fill="currentColor"
      />
      <path
        d="M19.2376 12.1925C19.2376 10.9202 18.7807 10.0391 18.3887 9.35335C17.8669 8.50538 17.3778 7.78728 17.3778 6.93931C17.3778 5.993 18.0955 5.11212 19.1064 5.11212C19.152 5.11212 19.1953 5.11781 19.2398 5.12035C17.4084 3.44243 14.9683 2.41797 12.2884 2.41797C8.69211 2.41797 5.52814 4.26317 3.6875 7.05801C3.92901 7.06526 4.15662 7.07035 4.34995 7.07035C5.42668 7.07035 7.09338 6.9397 7.09338 6.9397C7.64829 6.90699 7.71371 7.72205 7.15939 7.78767C7.15939 7.78767 6.60173 7.85329 5.9812 7.88581L9.72986 19.0364L11.9826 12.2798L10.3788 7.88542C9.82446 7.8529 9.29932 7.78728 9.29932 7.78728C8.7446 7.75476 8.80963 6.9066 9.36435 6.93931C9.36435 6.93931 11.0644 7.06996 12.0759 7.06996C13.1524 7.06996 14.8193 6.93931 14.8193 6.93931C15.3746 6.9066 15.4398 7.72166 14.8853 7.78728C14.8853 7.78728 14.3265 7.8529 13.7071 7.88542L17.4272 18.9516L18.4539 15.5203C18.899 14.0964 19.2376 13.0737 19.2376 12.1925Z"
        fill="currentColor"
      />
      <path
        d="M12.4675 13.6123L9.37891 22.5874C10.3011 22.8585 11.2763 23.0068 12.2869 23.0068C13.4856 23.0068 14.6352 22.7995 15.7053 22.4233C15.6777 22.3792 15.6526 22.3324 15.632 22.2814L12.4675 13.6123Z"
        fill="currentColor"
      />
      <path
        d="M21.3226 7.77246C21.3669 8.10037 21.3919 8.45237 21.3919 8.83101C21.3919 9.87564 21.1969 11.05 20.6092 12.5183L17.4648 21.6097C20.5252 19.8251 22.5837 16.5094 22.5837 12.7116C22.5838 10.9218 22.1267 9.23883 21.3226 7.77246Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.289062 12.7116C0.289062 6.09494 5.6723 0.711914 12.2888 0.711914C18.905 0.711914 24.2889 6.09494 24.2891 12.7116C24.2891 19.3291 18.905 24.7119 12.2888 24.7119C5.6723 24.7119 0.289062 19.3291 0.289062 12.7116ZM0.839353 12.7116C0.839353 19.0253 5.97579 24.1619 12.2888 24.1619C18.6017 24.1619 23.7377 19.0253 23.7377 12.7116C23.7377 6.39838 18.6017 1.26216 12.2888 1.26216C5.97559 1.26216 0.839353 6.39838 0.839353 12.7116Z"
        fill="currentColor"
      />
    </svg>
  )
}
