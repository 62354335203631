import React from "react"

export const GatsbyFrameworkIcon = ({ width = 32, height = 32, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M22.2857 15.9999H18.2857V17.1428H21.0286C20.6286 18.8571 19.3715 20.2857 17.7143 20.8571L11.1429 14.2857C11.8286 12.2857 13.7715 10.8571 16 10.8571C17.7143 10.8571 19.2572 11.7142 20.2286 13.0285L21.0857 12.2857C19.9429 10.7428 18.1143 9.71423 16 9.71423C13.0286 9.71423 10.5143 11.8285 9.88574 14.6285L17.4286 22.1714C20.1715 21.4857 22.2857 18.9714 22.2857 15.9999Z"
      fill="white"
    />
    <path
      d="M9.71436 16.0571C9.71436 17.6571 10.3429 19.2 11.5429 20.4C12.7429 21.6 14.3429 22.2286 15.8858 22.2286L9.71436 16.0571Z"
      fill="white"
    />
    <path
      d="M16 8C11.6 8 8 11.6 8 16C8 20.4 11.6 24 16 24C20.4 24 24 20.4 24 16C24 11.6 20.4 8 16 8ZM11.5429 20.4571C10.3429 19.2571 9.71429 17.6571 9.71429 16.1143L15.9429 22.2857C14.3429 22.2286 12.7429 21.6571 11.5429 20.4571ZM17.3714 22.1143L9.88571 14.6286C10.5143 11.8286 13.0286 9.71429 16 9.71429C18.1143 9.71429 19.9429 10.7429 21.0857 12.2857L20.2286 13.0286C19.2571 11.7143 17.7143 10.8571 16 10.8571C13.7714 10.8571 11.8857 12.2857 11.1429 14.2857L17.7143 20.8571C19.3714 20.2857 20.6286 18.8571 21.0286 17.1429H18.2857V16H22.2857C22.2857 18.9714 20.1714 21.4857 17.3714 22.1143Z"
      fill="#663399"
    />
    <circle cx="16" cy="16" r="10.5" stroke="#D9BAE8" />
    <circle cx="16" cy="16" r="14.5" stroke="#F1DEFA" />
    <circle cx="4.57129" cy="7.14233" r="1" fill="#663399" />
    <circle cx="24.5713" cy="10.1423" r="1" fill="#F06200" />
    <circle cx="10.5713" cy="25.1423" r="1" fill="#663399" />
    <circle cx="15.5713" cy="30.5" r="1" fill="#F06200" />
    <circle cx="29.5713" cy="21.1423" r="1" fill="#F06200" />
  </svg>
)
