import React from "react"

export const GatsbyCloudPreviewIcon = ({
  height = 16,
  width = 16,
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4973 8.18862C14.3975 11.6912 11.5268 14.5 8 14.5C4.47324 14.5 1.60249 11.6912 1.50268 8.18862C2.52668 10.7791 5.04804 12.6136 8 12.6136C10.952 12.6136 13.4733 10.7791 14.4973 8.18862ZM14.4977 8.17591C13.4739 5.58494 10.9523 3.75 8 3.75C5.0477 3.75 2.52611 5.58493 1.50233 8.1759C1.50078 8.11746 1.5 8.05882 1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8C14.5 8.05882 14.4992 8.11746 14.4977 8.17591ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM5.04545 8.18182C5.04545 9.81273 6.36909 11.1364 8 11.1364C9.63091 11.1364 10.9545 9.81273 10.9545 8.18182C10.9545 6.55091 9.63091 5.22727 8 5.22727C6.36909 5.22727 5.04545 6.55091 5.04545 8.18182ZM6.22727 8.18182C6.22727 7.20091 7.01909 6.40909 8 6.40909C8.98091 6.40909 9.77273 7.20091 9.77273 8.18182C9.77273 9.16273 8.98091 9.95455 8 9.95455C7.01909 9.95455 6.22727 9.16273 6.22727 8.18182Z"
        fill="currentColor"
      />
    </svg>
  )
}
